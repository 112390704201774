import SanityImage from 'gatsby-plugin-sanity-image';
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
// import YouTube from 'react-youtube';
// import getYouTubeID from 'get-youtube-id';
// import { EmbedStyles } from '../templates/Project';

const HomeStyles = styled.div`
  .youtube {
    margin: 2rem;
  }
  .home-img {
    margin: auto;
    margin-bottom: 5rem;
    display: block;

    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;

    @media (max-width: 1250px) {
      width: 95%;
      margin-bottom: 3rem;
    }
  }
`;

export default function HomePage({ data: { home } }) {
  return (
    <HomeStyles>
      <SEO title="Home" />
      {/* <h1>{home.title}</h1> */}

      <SanityImage
        {...home.image}
        className="pixelated-load shadow rounded home-img"
        title={home.image.alt}
      />
      {/* <EmbedStyles className="youtube">
        <YouTube videoId={getYouTubeID(home.youtube.url)} />
      </EmbedStyles> */}
      {/* <h3>
        Hello! My name is Nick and my hobbies/interests include history, photography, crosswords, leatherworking, reading, walking, sudoku, sewing, historical European martial arts, Dungeons & Dragons, game development, coding, cycling, table-top gaming, gaming, playing musical instruments, technology (retro, modern, & future), science fiction, space, astrophotography, photogrammetry, museums, fantasy, YA television from the mid 00s.
      </h3> */}
    </HomeStyles>
  );
}

export const query = graphql`
  query HomeQuery {
    home: sanityHome {
      id
      title
      image {
        ...ImageWithPreview
        alt
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      youtube {
        url
      }
    }
  }
`;
